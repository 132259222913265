import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/MainPage.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('../views/Registrate.vue')
  },
  {
    path: '/terms_of_use',
    name: 'terms_of_use',
    component: () => import('../views/Docs/TermsOfUse')
  },
  {
    path: '/confidentiality',
    name: 'confidentiality',
    component: () => import('../views/Docs/Confidentiality')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
