import { createStore } from 'vuex'
import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL
const strapiApiUrl = process.env.VUE_APP_STRAPI_API_URL
const strapiApiToken = process.env.VUE_APP_STRAPI_API_TOKEN

export default createStore({
  state: {
    translate: null,
    preloader: false,
  },
  getters: {
    preloaderState: state => state.preloader,
  },
  mutations: {
    preloaderStatus (state) {
      state.preloader = !state.preloader
    }
  },
  actions: {
    registrate({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + '/api/v1/user/school/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    checkSubdomain({ commit }, domain) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/check/domain/?domain=${domain}`,
          method: 'GET'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    checkEmail({ commit }, email) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/check/email/?email=${email}`,
          method: 'GET'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getContent({state}) {
      axios.get(strapiApiUrl + `/api/landing?populate=deep`, {
        headers: {
          'Authorization': `bearer ${strapiApiToken}`
        }
      }).then(response => {
        const data = response.data.data.attributes.content
        let content = {}
        data.forEach(item => {
          const key = item.__component.split('.')
          const concatKey = key[0] + key[1][0].toUpperCase() + key[1].slice(1)
          content[concatKey] = {
            ...item
          }
        })
        state.translate = content
      });
    }
  },
  modules: {
  }
})